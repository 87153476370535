<template>
  <div>
    <div class="row mt-5">
      <div class="col-md-12">
        <div>
          <b-button block v-b-toggle.collapse-habitats variant="primary"><h3>Habitats</h3></b-button>
          <b-collapse id="collapse-habitats">
            <div class="card card-custom gutter-b">
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label"></h3>
                </div>
                <div class="card-toolbar">
                  <a class="btn btn-icon btn-sm btn-primary mr-1"  @click="addHabitat">
                    <i class="icon-md fas fa-plus"></i>
                  </a>
                  <a class="btn btn-icon btn-sm btn-success mr-1" @click="save('habitats')">
                    <i class="icon-md fas fa-save"></i>
                  </a>
                </div>
              </div>
              <div class="card-body">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col" v-for="(l,i) in lang" :key="i">Nom {{l.titre}}</th>
                      <th scope="col">URL</th>
                      <th scope="col">Statut</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(elt,index) in habitats" :key="index">
                      <th  @click="test(elt)" scope="row">{{elt.id}}</th>
                      <td v-for="(l,i) in lang" :key="i"><input class="form-control" v-model="elt.nom[l.val]"/></td>
                      <td><input class="form-control" v-model="elt.url"/></td>
                      <td><b-form-checkbox size="lg" v-model="elt.status" value=1 unchecked-value=0 uncheck>Actif</b-form-checkbox></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">
        <div>
          <b-button block v-b-toggle.collapse-services variant="primary"><h3>Services</h3></b-button>
          <b-collapse id="collapse-services">
            <div class="card card-custom gutter-b">
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label"></h3>
                </div>
                <div class="card-toolbar">
                  <a class="btn btn-icon btn-sm btn-primary mr-1"  @click="addService">
                    <i class="icon-md fas fa-plus"></i>
                  </a>
                  <a class="btn btn-icon btn-sm btn-success mr-1" @click="save('services')">
                    <i class="icon-md fas fa-save"></i>
                  </a>
                </div>
              </div>
              <div class="card-body">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col" v-for="(l,i) in lang" :key="i">Nom {{l.titre}}</th>
                      <th scope="col">URL</th>
                      <th scope="col">Statut</th>
                      <th scope="col">Recherche</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(elt,index) in services" :key="index">
                      <th  @click="test(elt)" scope="row">{{elt.id}}</th>
                      <td v-for="(l,i) in lang" :key="i"><input class="form-control" v-model="elt.nom[l.val]"/></td>
                      <td><input class="form-control" v-model="elt.url"/></td>
                      <td><b-form-checkbox size="lg" v-model="elt.status" value=1 unchecked-value=0 uncheck>Actif</b-form-checkbox></td>
                      <td><b-form-checkbox size="lg" v-model="elt.search" value=1 unchecked-value=fa uncheck>Actif</b-form-checkbox></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">
        <div>
          <b-button block v-b-toggle.collapse-solutions variant="primary"><h3>Solutions</h3></b-button>
          <b-collapse id="collapse-solutions">
            <div class="card card-custom gutter-b">
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label"></h3>
                </div>
                <div class="card-toolbar">
                  <a class="btn btn-icon btn-sm btn-primary mr-1"  @click="addSolution">
                    <i class="icon-md fas fa-plus"></i>
                  </a>
                  <a class="btn btn-icon btn-sm btn-success mr-1" @click="save('solutions')">
                    <i class="icon-md fas fa-save"></i>
                  </a>
                </div>
              </div>
              <div class="card-body">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col" v-for="(l,i) in lang" :key="i">Nom {{l.titre}}</th>
                      <th scope="col">Statut</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(elt,index) in solutions" :key="index">
                      <th  @click="test(elt)" scope="row">{{elt.id}}</th>
                      <td v-for="(l,i) in lang" :key="i"><input class="form-control" v-model="elt.nom[l.val]"/></td>
                      <td><b-form-checkbox size="lg" v-model="elt.status" value=1 unchecked-value=0 uncheck>Actif</b-form-checkbox></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">
        <div>
          <b-button block v-b-toggle.collapse-options variant="primary"><h3>Options</h3></b-button>
          <b-collapse id="collapse-options">
            <div class="card card-custom gutter-b">
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label"></h3>
                </div>
                <div class="card-toolbar">
                  <a class="btn btn-icon btn-sm btn-primary mr-1"  @click="addOption">
                    <i class="icon-md fas fa-plus"></i>
                  </a>
                  <a class="btn btn-icon btn-sm btn-success mr-1" @click="save('options')">
                    <i class="icon-md fas fa-save"></i>
                  </a>
                </div>
              </div>
              <div class="card-body">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col" v-for="(l,i) in lang" :key="i">Nom {{l.titre}}</th>
                      <th scope="col">Class</th>
                      <th scope="col">Statut</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(elt,index) in options" :key="index">
                      <th  @click="test(elt)" scope="row">{{elt.id}}</th>
                      <td v-for="(l,i) in lang" :key="i"><input class="form-control" v-model="elt.nom[l.val]"/></td>
                      <td><input class="form-control" v-model="elt.class"/></td>
                      <td><b-form-checkbox size="lg" v-model="elt.status" value=1 unchecked-value=0 uncheck>Actif</b-form-checkbox></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data(){
    return {
      lang:[],
      habitats:[],
      services:[],
      solutions:[],
      options:[],
    };
  },
  components: {
  },
  watch: {
  },
  methods: {
    addHabitat(){
      var tmp = {
        id:0,
        nom: {},
        url: null,
        status:1
      };
      this.lang.forEach((item) => {
        tmp.nom[item.val] = null;
      });
      this.habitats.push(tmp);
    },
    addService(){
      var tmp = {
        id:0,
        nom: {},
        url: null,
        status:1
      };
      this.lang.forEach((item) => {
        tmp.nom[item.val] = null;
      });
      this.services.push(tmp);
    },
    addSolution(){
      var tmp = {
        id:0,
        nom: {},
        status:1
      };
      this.lang.forEach((item) => {
        tmp.nom[item.val] = null;
      });
      this.solutions.push(tmp);
    },
    addOption(){
      var tmp = {
        id:0,
        nom: {},
        class: null,
        status:1
      };
      this.lang.forEach((item) => {
        tmp.nom[item.val] = null;
      });
      this.options.push(tmp);
    },
    save(type){
      let params = {
        type:type,
        vals: this[type]
      };
      this.$store.back.ajax('/parametrage/all', params, (res) => {
        if(res.status === true){
          this[type] = res.data;
          this.$bvToast.toast(`Votre parametrage est bien sauvegarde !`, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          })
        }
      });
    }
  },
  computed: {
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" }
    ]);
    this.$store.back.ajax('/parametrage/all', null, (res) => {
      if(res.status === true){
        this.habitats = res.data.habitats;
        this.services = res.data.services;
        this.solutions = res.data.solutions;
        this.options = res.data.options;
        this.lang = res.data.langues;
        console.log(this.lang)
      }
    });
  }
};
</script>
